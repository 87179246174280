/*
 * Taken from https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32914
 */

export const breakpoints = {
  xs: 0,
  sm: 40, // 640
  md: 48, // 768
  lg: 64, // 1024
  xl: 80, // 1280
};

export const media = (
  Object.keys(breakpoints) as Array<keyof typeof breakpoints>
).reduce((acc, breakpoint) => {
  acc[breakpoint] = () =>
    `@media screen and (min-width: ${breakpoints[breakpoint]}rem), print`;
  return acc;
}, {} as { [key in keyof typeof breakpoints]: () => string });

/* Example
const SomeDiv = styled.div`
  display: flex;
  ....
  ${media.xs} {
    display: block
  }
`;
*/
