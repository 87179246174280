import styled from 'styled-components/macro';
import { CircleImage } from '../CircleImage';
import { PropsWithChildren } from 'react';
import { Col, Row } from '../Grid';

export const Meta = styled.div`
  font-size: 15px;
  line-height: 18px;

  & > img,
  & > svg,
  & > ${CircleImage} {
    max-width: 20px;
    max-height: 20px;
    margin-right: 6px;
    vertical-align: bottom;
    position: relative;
    display: inline-block;
  }
`;

interface Props {
  icon?: any;
}

export const Meta2 = (props: PropsWithChildren<Props>) => {
  const { children, icon } = props;

  return (
    <Row gutter={0.375} alignItems="center">
      {icon ? (
        <Col gutter={0.375}>
          <IconWrapper>{icon}</IconWrapper>
        </Col>
      ) : null}
      <Col gutter={0.375} xs="auto">
        {children}
      </Col>
    </Row>
  );
};

export const Meta3 = (props: PropsWithChildren<Props>) => {
  const { children, icon } = props;

  return (
    <Wrapper>
      <Row gutter={0.375} alignItems="center">
        {icon ? (
          <Col gutter={0.375} alignSelf="flex-start">
            <IconWrapper>{icon}</IconWrapper>
          </Col>
        ) : null}
        <Col gutter={0.375} xs="auto">
          {children}
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 15px;
  line-height: 18px;
`;

/* TODO: Refactor after move all components to Meta2  */
const IconWrapper = styled.div`
  line-height: 0;

  svg {
    top: 0 !important;
  }
`;
