import React, { memo } from 'react';

import { Col, Row } from '../Grid';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';

export const NavBar = memo(() => {
  return (
    <Row>
      <Col xs={12} display={{ xs: 'none', lg: 'block' }}>
        <DesktopNav />
      </Col>
      <Col xs={12} display={{ xs: 'block', lg: 'none' }}>
        <MobileNav />
      </Col>
    </Row>
  );
});
