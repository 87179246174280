import React from 'react';
import raw from 'raw.macro';
import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';

export type IconName =
  | 'home'
  | 'zero'
  | 'face-happy-outlined'
  | 'face-happy-dashed'
  | 'face-sad-outlined'
  | 'face-sad-dashed'
  | 'person'
  | 'news'
  | 'discover'
  | 'bookmark'
  | 'settings'
  | 'log-out'
  | 'chevron'
  | 'chevron-left'
  | 'pencil'
  | 'trash'
  | 'plus'
  | 'ball'
  | 'arrow-up'
  | 'pitch'
  | 'card-yellow'
  | 'card-red'
  | 'instagram'
  | 'facebook'
  | 'twitter'
  | 'transfermarkt'
  | 'question'
  | 'copy'
  | 'cup'
  | 'filter'
  | 'checkbox'
  | 'cross'
  | 'cross-small-1'
  | 'search'
  | 'pitch-pro'
  | 'pitch-youth'
  | 'distance-1'
  | 'sprint'
  | 'duel'
  | 'goals'
  | 'gates'
  | 'spin-1'
  | 'no-search-result'
  | 'grid'
  | 'list'
  | 'key'
  | 'slider-arrow-left-1'
  | 'slider-arrow-left-3'
  | 'slider-arrow-right-1'
  | 'slider-arrow-right-3'
  | 'report'
  | 'note'
  | 'file'
  | 'checkmark'
  | 'checkmark-small'
  | 'download'
  | 'impect'
  | 'impect-1'
  | 'arrow-left'
  | 'arrow-right'
  | 'close'
  | 'compare'
  | 'email-1'
  | 'arrow-right-small'
  | 'share-5'
  | 'info-1'
  | 'customize'
  | 'linkedin'
  | 'heading'
  | 'pressure'
  | 'list-thin-1'
  | 'text'
  | 'calendar'
  | 'relation-1'
  | 'chevron-left-small'
  | 'team-1'
  | 'logo'
  | 'star-outlined-1'
  | 'shield-dashed-2'
  | 'bell'
  | 'bar-chart-outlined'
  | 'distance'
  | 'sort';

interface Props {
  name: IconName;
}

const fillIcons: IconName[] = [
  'news',
  'settings',
  'log-out',
  'trash',
  'plus',
  'cup',
  'arrow-up',
  'pitch',
  'instagram',
  'facebook',
  'twitter',
  'transfermarkt',
  'question',
  'cross',
  'filter',
  'search',
  'distance-1',
  'spin-1',
  'slider-arrow-left-1',
  'slider-arrow-left-3',
  'slider-arrow-right-1',
  'slider-arrow-right-3',
  'linkedin',
  'report',
  'note',
  'file',
  'arrow-left',
  'arrow-right',
  'customize',
  'arrow-right-small',
  'relation-1',
  'chevron-left',
  'impect',
  'logo',
  'home',
  'zero',
];

export const Icon = (props: Props) => {
  const { name } = props;

  const svg = raw(`./assets/${name}.svg`);
  let type: string | undefined =
    fillIcons.indexOf(name) !== -1 ? 'fill' : 'stroke';

  switch (name) {
    case 'face-happy-outlined':
    case 'face-happy-dashed':
    case 'face-sad-outlined':
    case 'face-sad-dashed':
    case 'distance':
    case 'zero':
      type = undefined;
      break;
    default:
      break;
  }

  return (
    <IconWrapper
      type={type}
      {...props}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

interface WrapperProps {
  readonly type?: string;
}

export const IconWrapper = styled.span<WrapperProps>`
  line-height: 0;

  svg {
    ${switchProp('type', {
      fill: css`
        fill: currentColor;
      `,
      stroke: css`
        stroke: currentColor;
      `,
    })}
  }
`;
