import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

import { Loader as LoaderOrigin } from '../Loader';
import { ButtonIcon } from '../ButtonIcon';
import { Icon } from '../Icon';
import { media } from '../../../styles/media';

interface Props {
  loading?: boolean;
  fullHeight?: boolean;
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export function PageWrapper(props: PropsWithChildren<Props>) {
  const { loading, children, fullHeight } = props;
  const [toTopVisible, setToTopVisible] = useState(false);

  const onScroll = () => {
    setToTopVisible(document.documentElement.scrollTop > 400);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Wrapper>
      <Container $fullHeight={fullHeight}>
        {children}
        {loading ? <Loader /> : null}
        <ScrollToTop visible={toTopVisible}>
          <ButtonIcon onClick={scrollToTop} palette="tertiary">
            <Icon name="chevron" />
          </ButtonIcon>
        </ScrollToTop>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: var(--safe-area-inset-top, 1rem) 1rem
    calc(var(--safe-area-inset-bottom, 6px) + 4.5rem);

  ${media.md} {
    padding: var(--safe-area-inset-top, 2rem) 2rem
      calc(var(--safe-area-inset-bottom, 6px) + 4.5rem);
  }

  ${media.lg} {
    padding: var(--safe-area-inset-top, 1.5rem) 1.5rem
      calc(var(--safe-area-inset-bottom, 6px) + 1.5rem) 15.5rem;
  }

  ${media.xl} {
    padding: var(--safe-area-inset-top, 2rem) 2rem 2rem 17rem;
  }

  @media print {
    padding: 0;
  }
`;

const Container = styled.div<{ $fullHeight?: boolean }>`
  width: 100%;
  max-width: 1112px;
  margin: 0 auto;

  ${ifProp(
    '$fullHeight',
    css`
      min-height: calc(100vh - 4rem);
      display: grid;
    `,
  )};
`;

const Loader = styled(LoaderOrigin)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 255;

  ${media.lg} {
    left: 14rem;
  }

  ${media.xl} {
    left: 15rem;
  }
`;

const ScrollToTop = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 1rem;
  left: calc(50% + 15rem / 2);
  margin-left: calc(540px + 2rem);
  opacity: ${ifProp('visible', 1, 0)};
  transition: opacity 0.2s;

  svg {
    transform: rotate(180deg);
  }
`;
