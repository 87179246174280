import styled from 'styled-components/macro';
import ReactTooltipOrigin from 'react-tooltip';

import { palette } from '../../../styles/tools';

export const Tooltip = styled(ReactTooltipOrigin)`
  &&& {
    padding: 0.25rem 0.5rem;
    border: 0.5px solid ${palette('tooltip', 'default', 0)};
    background: ${palette('tooltip', 'default', 1)};
    font-size: 16px;
    line-height: 24px;
    color: ${palette('tooltip', 'default', 2)};
    max-width: min(calc(100vw - 2rem), 25rem);
    border-radius: 0.25rem;
    backdrop-filter: blur(0.5rem);
    white-space: pre-line;
    opacity: 1;

    &:before,
    &:after {
      display: none;
    }
  }
`;
