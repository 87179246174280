import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { AxiosError } from 'axios';

import { dictSaga } from './saga';
import { DictState } from './types';
import { parseDate } from '../../utils/date';

export const initialState: DictState = {
  loading: false,
  loaded: false,
  languages: [],
  agencies: [],
  coachIndexes: [],
  coachTypes: [],
  licenses: [],
  newsTypes: [],
  seasons: [],
  gameStyles: [],
  formations: [],
  countries: [],
  teams: [],
  competitions: [],
  ages: [],
};

const getDictValues = (action, key) => {
  return action.payload[key] instanceof AxiosError
    ? initialState[key]
    : action.payload[key].data;
};

const slice = createSlice({
  name: 'dict',
  initialState,
  reducers: {
    getDict(state) {
      state.loading = true;
    },
    fillDict(state, action: PayloadAction<any>) {
      state.loaded = false;
      state.loading = false;

      state.languages = getDictValues(action, 'languages');
      state.agencies = getDictValues(action, 'agencies');
      state.coachIndexes = getDictValues(action, 'coachIndexes');
      state.coachTypes = getDictValues(action, 'coachTypes');
      state.licenses = getDictValues(action, 'licenses');
      state.newsTypes = getDictValues(action, 'newsTypes');
      state.seasons = getDictValues(action, 'seasons').map(season => ({
        ...season,
        startdate: parseDate(season.startdate, 'yyyy-MM-dd'),
        enddate: parseDate(season.enddate, 'yyyy-MM-dd'),
      }));
      state.gameStyles = getDictValues(action, 'gameStyles');
      state.formations = getDictValues(action, 'formations');
      state.countries = getDictValues(action, 'countries');
      state.teams = getDictValues(action, 'teams');
      state.competitions = getDictValues(action, 'competitions');
      state.ages = getDictValues(action, 'ages');
    },
  },
});

export const { actions: dictActions } = slice;

export const useDictSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dictSaga });
  return { actions: slice.actions };
};
