import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';

import { palette } from '../../../../styles/tools';
import { UserValidateProps } from '../../../../utils/validate-user-permissions';
import { usePermissions } from '../../../../hooks/usePermissions';
import { media } from '../../../../styles/media';

type LinkProps = NavLinkProps & UserValidateProps;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  UserValidateProps;

export const NavLink = ({ permissions, tags, ...props }: LinkProps) => {
  const hasAccess = usePermissions({ permissions, tags });

  if (!hasAccess) return null;

  return <NavLinkStyled {...props} />;
};

export const NavButton = ({ permissions, tags, ...props }: ButtonProps) => {
  const hasAccess = usePermissions({ permissions, tags });

  if (!hasAccess) return null;

  return <NavButtonStyled {...props} />;
};

const NavItemStyle = css`
  padding: 0.25rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.8px;
  color: ${palette('link', 'default', 0)};
  border: none;
  cursor: pointer;
  flex: auto;

  ${media.md} {
    font-size: 13px;
    line-height: 16px;
  }

  ${media.lg} {
    padding: 0.25rem 0.5rem;
    flex: none;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  &:hover {
    color: ${palette('link', 'default', 1)};
  }

  &.active {
    color: ${palette('link', 'default', 2)};
  }

  svg {
    margin-bottom: 2px;
  }
`;

export const NavLinkStyled = styled(RouterNavLink)`
  ${NavItemStyle}
`;

export const NavButtonStyled = styled.button`
  ${NavItemStyle}
`;
