import dateParse from 'date-fns/parse';
import dateFormat from 'date-fns/format';
import isValid from 'date-fns/isValid';
import intervalToDuration from 'date-fns/intervalToDuration';
import formatDuration from 'date-fns/formatDuration';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import tr from 'date-fns/locale/tr';
import enGB from 'date-fns/locale/en-GB';
import i18n from 'i18next';

export const getDateLocale = (): Locale => {
  switch (i18n.language) {
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'it':
      return it;
    case 'es':
      return es;
    case 'tr':
      return tr;
    default:
      return enGB;
  }
};

export const parseDate = (
  date: string | undefined | null,
  format: string,
): Date | null => {
  if (!date) return null;

  try {
    const dateParsed = dateParse(date, format, new Date());
    if (isValid(dateParsed)) {
      return dateParsed;
    }

    return isValid(dateParsed) ? dateParsed : null;
  } catch (error) {
    return null;
  }
};

export const formatDate = (
  date: Date | undefined | null,
  format: string,
): string | null => {
  if (!date || !isValid(date)) return null;

  try {
    return dateFormat(date, format);
  } catch (error) {
    return null;
  }
};

export const getDuration = (
  timeUnix: number,
  units: string[] = ['years', 'months', 'days'],
): string | null => {
  const duration = intervalToDuration({
    start: 0,
    end: timeUnix,
  });

  let durationValue: string | null = null;
  if (units.length) {
    durationValue = formatDuration(duration, {
      format: units,
      locale: getDateLocale(),
    });
  }

  return durationValue;
};
