import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components/macro';

import { Loader as LoaderOrigin } from '../app/components/Loader';
import { PageError } from '../app/components/Messages';
import { media } from '../styles/media';
import { ifProp } from '../styles/tools';

interface Options {
  fallback: React.ReactNode;
  withoutNavigation?: boolean;
}
type Unpromisify<T> = T extends Promise<infer P> ? P : never;

export const lazyLoad = <
  T extends Promise<any>,
  U extends React.ComponentType<any>,
>(
  importFunc: () => T,
  selectorFunc?: (s: Unpromisify<T>) => U,
  options: Options = { fallback: null },
) => {
  const { fallback, withoutNavigation } = options;

  let lazyFactory: () => Promise<{ default: U }> = importFunc;

  if (selectorFunc) {
    lazyFactory = () =>
      importFunc().then(module => ({ default: selectorFunc(module) }));
  }

  const LazyComponent = lazy(lazyFactory);

  return (props: React.ComponentProps<U>): JSX.Element => (
    <Suspense
      fallback={fallback! || <Loader withoutNavigation={withoutNavigation} />}
    >
      <ErrorBoundary fallback={<PageError />}>
        <LazyComponent {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const Loader = styled(LoaderOrigin)<{ withoutNavigation?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${media.lg} {
    left: ${ifProp('withoutNavigation', 0, 14)}rem;
  }

  ${media.xl} {
    left: ${ifProp('withoutNavigation', 0, 15)}rem;
  }
`;
